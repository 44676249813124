import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import NavBar from "../components/NavBar"
import map from "../images/map.jpg"
import LocationSection from "../components/LocationSection"

export default function Locations(props) {
    return (
        <Layout>
            <SEO
                title="Locations"
            />
            <NavBar />
            <LocationSection
                img={map}
                order="order-first"
                intro="Serving"
                title="The Eastern Massachusetts Area"
                detail="Premier vending for Eastern Massachusetts and beyond."
                feature="Here is a list of locations we service:"
                locationList={["Boston, MA", "Worcester, MA", "Somerville, MA", "Cambridge, MA", "Brookline, MA", "Waltham, MA",
                    "Burlington, MA", "Concord, MA", "Woburn, MA", "Lynn, MA", "Milford, MA", "Beverly, MA", "Salem, MA"]}
                locationList2={["Peabody, MA", "Topsfield, MA", "Middleton, MA", "Andover, MA", "Haverhill, MA", "Gloucester, MA", "Fall River, MA",
                    "Lowell, MA", "Newbury, MA", "Nashua, NH", "Seabrook, NH"]}
                question="Don't see your location?"
            />
            <Footer />

        </Layout>
    )
}
