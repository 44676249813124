import React from "react"

export default function LocationSection(props) {
    return (
        <div className="bg-black h-full">
            <div className="flex flex-col sm:flex-row">
                <div className={"flex flex-col w-full sm:w-2/5 bg-cover px-8 py-8 " + props.order} style={{ backgroundImage: `url(${props.img})` }}>
                    <p className="block sm:hidden text-secondary uppercase font-body tracking-widest mb-4">{props.intro}</p>
                    <h1 className="block sm:hidden text-4xl tracking-tight leading-10 font-extrabold text-white font-display sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                        {props.title} <br />
                        {props.title2}
                    </h1>
                    <p className="block sm:hidden text-white font-body mt-4">
                        {props.detail}
                    </p>
                    <div className="block sm:hidden text-secondary font-body mt-8 font-light">
                        {props.body}
                        <p className="mt-4 font-medium">
                            {props.feature}
                        </p>
                        <p className="mt-2">
                            {props.featureDetail}
                        </p>
                        <p className="mt-4 font-medium">
                            {props.feature2}
                        </p>
                        <p className="mt-2">
                            {props.featureDetail2}
                        </p>
                        <p className="mt-4 font-medium">
                            {props.feature3}
                        </p>
                        <p className="mt-2">
                            {props.featureDetail3}
                        </p>
                        <ul className="mt-2 flex flex-row space-x-24">
                            <li className="whitespace-pre-wrap">
                                {props.locationList.join("\n")}
                            </li>
                            <li className="whitespace-pre-wrap">
                                {props.locationList.join("\n")}
                            </li>
                        </ul>
                        <p className="mt-8">
                            {props.question}
                        </p>
                        <div className={"mt-6 " + props.showCta}>
                            <a href="/contact" aria-label="Contact button" alt="Contact button" className="bg-yellow-300 text-black text-center font-bold tracking-tight w-48 shadow-md font-body py-3 px-8 rounded">
                                Contact Us
                        </a>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-2/5 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:py-32">
                    <p className="hidden sm:block text-secondary uppercase font-body tracking-widest mb-4">{props.intro}</p>
                    <h1 className="hidden sm:block text-4xl tracking-tight leading-10 font-extrabold text-white font-display sm:text-5xl sm:leading-none md:text-6xl lg:text-5xl xl:text-6xl">
                        {props.title} <br />
                        {props.title2}
                    </h1>
                    <p className="hidden sm:block text-white font-body mt-4">
                        {props.detail}
                    </p>
                    <div className="hidden sm:block text-secondary font-body mt-8 font-light leading-10">
                        {props.body}
                        <p className="mt-4 font-medium">
                            {props.feature}
                        </p>
                        <p className="mt-2">
                            {props.featureDetail}
                        </p>
                        <p className="mt-4 font-medium">
                            {props.feature2}
                        </p>
                        <p className="mt-2">
                            {props.featureDetail2}
                        </p>
                        <p className="mt-4 font-medium">
                            {props.feature3}
                        </p>
                        <p className="mt-2">
                            {props.featureDetail3}
                        </p>
                        <ul className="mt-2 flex flex-row space-x-12">
                            <li className="whitespace-pre-wrap">
                                {props.locationList.join("\n")}
                            </li>
                            <li className="whitespace-pre-wrap">
                                {props.locationList2.join("\n")}
                            </li>
                        </ul>
                        <p className="mt-8">
                            {props.question}
                        </p>
                        <div className={"mt-4 " + props.showCta}>
                            <a href="/contact" aria-label="Contact button" alt="Contact button" className="bg-yellow-300 text-black text-center font-bold tracking-tight w-48 shadow-md font-body py-3 px-8 rounded">
                                Contact Us
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}